<template>
    <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
        <div class="card border-0">
            <div class="card-body font-weight-bolder font-medium-4 border-0 bg-blue text-center">
                Sua programação para promoção de saúde e prevenção de doenças
            </div>
            <div class="card-body bg-light text-dark p-0">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-12">
                        <table class="table table-bordered font-medium-3">
                            <caption>Recomendação para Homens e Mulheres</caption>
                            <tbody>
                            <tr class="align-items-center">
                                <th id="recomendacao" class="font-weight-bolder font-medium-4 text-blue p-2 bg-light-secondary">Recomendação para Homens e Mulheres</th>
                                <th
                                  id="idadeDescricao"
                                  class="font-weight-bolder font-medium-4 text-center p-0"
                                  :class="
                                    (colaboradorIdade>17 && colaboradorIdade<=29 && idade.descricao == '18 a 29 anos') ||
                                    (colaboradorIdade>29 && colaboradorIdade<=39 && idade.descricao == '30 a 39 anos') ||
                                    (colaboradorIdade>=40 && idade.descricao == '+40 anos') ? 'bg-success text-white font-weight-bolder'
                                    : 'text-primary font-weight-bold bg-light-secondary text-blue'
                                  "
                                  v-for="(idade,index) in menuIdades"
                                  :key="index"
                                >
                                  <div>
                                    {{ idade.descricao }}
                                  </div>
                                </th>
                            </tr>
                            <tr class="align-items-center" v-for="(atividade,index) in menuRecomendacoes" :key="index">
                                <th id="atividadeDescricao" class="font-medium-3 text-blue font-weight-bolder p-2 bg-light" style="width: 35%">{{ atividade.descricao }}</th>
                                <td :class="[colaboradorIdade<=29?'font-weight-bolder bg-light-success':'font-weight-normal']+' text-center p-2'">{{ programacao.faixaIdade1[atividade.key] }}</td>
                                <td :class="[colaboradorIdade>29 && colaboradorIdade<=39?'font-weight-bolder bg-light-success':'font-weight-normal']+' text-center p-2'">{{ programacao.faixaIdade2[atividade.key] }}</td>
                                <td :class="[colaboradorIdade>=40?'font-weight-bolder bg-light-success':'font-weight-normal']+' text-center p-2'">{{ programacao.faixaIdade3[atividade.key] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="font-medium-4 font-weight-bolder mb-1">Essas recomendações são baseadas nas diretrizes atuais de diversos especialistas</div>
                <div class="font-medium-2 font-weight-normal">
                    Observação: A frequência das avaliações e recomendações é baseada em indivíduos que se submetem a
                    avaliações regulares e cujos os resultados tenham sido normais. Se você teve ou tem um problema de saúde,
                    suas avaliações provavelmente precisarão ser mais frequentes. Converse com o seu médico para ter orientação
                    sobre suas avaliações e como elas tem impacto em você.
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import {
    BOverlay, BBadge,BProgress
} from 'bootstrap-vue'

const COLOR = {
    purple: '#9342cf',
    purpleDark: '#5f05cc',
    blue: '#0e67c4',
    blueDark: '#15249a',
    green: '#2ED24AFF',
    greenLight: 'rgba(46,210,74,0.11)',
    red: '#a90303',
    orange: '#f88129',
    yellow: '#f8c621',
    light: '#ededed'
};

export default {
    components: {
        BBadge,BOverlay,BProgress
    },
    props: {
        dados: {
            type: Object,
            default() {
                return {};
            },
            required: false,
        },
    },
    data() {
        return {
            colaboradorIdade: 0,
            legenda: {},
            loadingShow: true,
            menuRecomendacoes: [
                {key: 'atividadeFisica',descricao: 'Tempo de atividade física semanal'},
                {key: 'frutasVerduras',descricao: 'Consumo de Frutas e Verduras'},
                {key: 'pressaoArterial',descricao: 'Verificar pressão arterial'},
                {key: 'colesterol',descricao: 'Verificar colesterol'},
                {key: 'diabetes',descricao: 'Verificar diabetes'},
            ],
            menuIdades: [
                {descricao: '18 a 29 anos'},
                {descricao: '30 a 39 anos'},
                {descricao: '+40 anos'},
            ],
            programacao: {
                faixaIdade1: {
                    'atividadeFisica': '150 minutos',
                    'frutasVerduras': 'Diariamente',
                    'pressaoArterial': 'A cada 2 anos',
                    'colesterol': 'A cada 5 anos',
                    'diabetes': '-',
                },
                faixaIdade2: {
                    'atividadeFisica': '150 minutos',
                    'frutasVerduras': 'Diariamente',
                    'pressaoArterial': 'A cada 2 anos',
                    'colesterol': 'A cada 5 anos',
                    'diabetes': 'A cada 5 anos',
                },
                faixaIdade3: {
                    'atividadeFisica': '150 minutos',
                    'frutasVerduras': 'Diariamente',
                    'pressaoArterial': 'Anualmente',
                    'colesterol': 'A cada 3 anos',
                    'diabetes': 'A cada 3 anos',
                },
            }
        }
    },
    mounted() {
        this.colaboradorIdade = this.getAge(this.$props.dados.colaborador.nascimento);
    },
    methods: {
        getRisco(nivel){
            if(nivel <= 1){
                return {progress: 17, cor: 'success'}
            }
            if(nivel <= 3){
                return {progress: 50, cor: 'warning'}
            }
            return {progress: 83, cor: 'danger'}
        },
        getAge(dateString) {
            const today = new Date();
            const birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom/relatorios.scss";
</style>

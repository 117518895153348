<template>
  <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
    <div class="card border-0 bg-blue">
      <div class="card-body font-weight-bolder font-medium-4 border-0 bg-blue text-center">
        Fatores de risco acumulados
      </div>
      <div class="card-body bg-light text-dark p-2">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="card rounded border mb-0">
              <div class="list-group-flush">
                <div class="list-group-item py-2">
                  <div class="row no-gutters align-items-center">
                    <div class="col-4 text-right">
                      <div class="pr-2 font-medium-2 font-weight-bold text-muted">
                        {{ estiloVida.descricao }}
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="progress-wrapper">
                        <div class="row" style="padding-bottom: 5px">
                          <div class="col-6">
                            <b-card-text class="mb-0 font-weight-bolder font-medium-3">
                              <span v-bind:class="['text-'+estiloVida.cor]">{{ estiloVida.numero }} risco(s)</span>
                            </b-card-text>
                          </div>
                          <div class="col-6 text-right">
                            <b-card-text class="mb-0 text-muted font-weight-bolder font-medium-1">
                              Máximo de 8 riscos
                            </b-card-text>
                          </div>
                        </div>
                        <div :class="['rounded-0 progress bg-'+estiloVida.cor+'-light']" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height: 25px">
                          <div :class="['progress-bar bg-'+estiloVida.cor]" :style="{borderRadius:0, width: estiloVida.progress+'%'}"></div>
                        </div>
                        <b-card-text class="mb-0 font-small-2">
                          {{ estiloVida.riscos }}
                        </b-card-text>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item py-2">
                  <div class="row no-gutters align-items-center">
                    <div class="col-4 text-right">
                      <div class="mr-2 font-medium-2 font-weight-bold text-muted">
                        {{ estresse.descricao }}
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="progress-wrapper">
                        <div class="row" style="padding-bottom: 5px">
                          <div class="col-6">
                            <b-card-text class="mb-0 font-weight-bolder font-medium-3">
                              <span v-bind:class="['text-'+estresse.cor]">{{ estresse.numero }} risco(s)</span>
                            </b-card-text>
                          </div>
                          <div class="col-6 text-right">
                            <b-card-text class="mb-0 text-muted font-weight-bolder font-medium-1">
                              Máximo de 4 riscos
                            </b-card-text>
                          </div>
                        </div>
                        <div :class="['rounded-0 progress bg-'+estresse.cor+'-light']" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height: 25px">
                          <div :class="['progress-bar bg-'+estresse.cor]" :style="{borderRadius:0, width: estresse.progress+'%'}"></div>
                        </div>
                        <b-card-text class="mb-0 font-small-2">
                          {{ estresse.riscos }}
                        </b-card-text>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item py-2">
                  <div class="row no-gutters align-items-center">
                    <div class="col-4 text-right">
                      <div class="pr-2 font-medium-2 font-weight-bold text-muted">
                        {{ enfermidade.descricao }}
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="progress-wrapper">
                        <div class="row" style="padding-bottom: 5px">
                          <div class="col-6">
                            <b-card-text class="mb-0 font-weight-bolder font-medium-3">
                              <span v-bind:class="['text-'+enfermidade.cor]">{{ enfermidade.numero }} risco(s)</span>
                            </b-card-text>
                          </div>
                          <div class="col-6 text-right">
                            <b-card-text class="mb-0 text-muted font-weight-bolder font-medium-1">
                              Máximo de 10 riscos
                            </b-card-text>
                          </div>
                        </div>
                        <div :class="['rounded-0 progress bg-'+enfermidade.cor+'-light']" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height:25px">
                          <div :class="['progress-bar bg-'+enfermidade.cor]" :style="{borderRadius:0, width: enfermidade.progress+'%'}"></div>
                        </div>
                        <b-card-text class="mb-0 font-small-2">
                          {{ enfermidade.riscos }}
                        </b-card-text>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item bg-light">
                  <div class="row align-items-center font-medium-1 font-weight-bolder">
                    <div class="col-12 col-md-3">
                      <div class="font-weight-bolder text-right pl-1">Risco acumulado</div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="card mb-0 rounded-0 bg-light">
                        <div class="card-body p-1">
                          <div class="row no-gutters justify-content-center align-items-center">
                            <div class="col-auto">
                              <div class="bullet-legenda bg-green cursor-pointer float-right mr-1"></div>
                            </div>
                            <div class="col text-left text-green">Baixo:<br><span class="">1 ou menos</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="card mb-0 rounded-0 bg-light">
                        <div class="card-body p-1">
                          <div class="row no-gutters justify-content-center align-items-center">
                            <div class="col-auto">
                              <div class="bullet-legenda bg-blue cursor-pointer float-right mr-1"></div>
                            </div>
                            <div class="col text-left text-blue">Médio:<br>entre 2 e 3</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="card mb-0 rounded-0 bg-light">
                        <div class="card-body p-1">
                          <div class="row no-gutters justify-content-center align-items-center">
                            <div class="col-auto">
                              <div class="bullet-legenda bg-purple cursor-pointer float-right mr-1"></div>
                            </div>
                            <div class="col text-left text-purple">Alto:<br>4 ou mais</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BBadge,BProgress,BCardText
} from 'bootstrap-vue'

const COLOR = {
  purple: '#aa228c',
  blue: '#2772c0',
  green: '#36ba00',
  light: '#ededed'
};

export default {
  components: {
    BBadge,BOverlay,BProgress,BCardText
  },
  props: {
    dados: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
  },
  data() {
    return {
      COR_VERDE: "green",
      COR_AZUL: "blue",
      COR_ROXA: "purple",

      LIMITE_4: 4,
      LIMITE_8: 8,

      legenda: {},
      loadingShow: true,
      estiloVida: {
        descricao: 'Estilo de vida',
        numero: 0,
        riscos: null,
        progress: 0,
        cor: COLOR.light
      },
      estresse: {
        descricao: 'Estresse',
        numero: 0,
        riscos: null,
        progress: 0,
        cor: COLOR.light
      },
      enfermidade: {
        descricao: 'Doenças crônicas não transmissíveis',
        numero: 0,
        riscos: null,
        progress: 0,
        cor: COLOR.light
      },
      niveisDeRisco: [
        {descricao: 'Baixo', cor: COLOR.green},
        {descricao: 'Medio', cor: COLOR.blue},
        {descricao: 'Alto', cor: COLOR.purple},
      ]
    }
  },
  mounted() {
    this.enfermidade.numero = this.$props.dados.enfermidade.pontuacao.toString().replace(/\.00$/,'');
    this.estresse.numero = this.$props.dados.estresse.pontuacao.toString().replace(/\.00$/,'');
    this.estiloVida.numero = this.$props.dados.estilo_vida.pontuacao.toString().replace(/\.00$/,'');

    let dadosRisco = this.getRisco(this.enfermidade.numero, 10, this.$props.dados.enfermidade.descricao_area_risco)
    this.enfermidade.cor = dadosRisco.cor;
    this.enfermidade.progress = dadosRisco.progress;
    this.enfermidade.riscos = dadosRisco.desc;

    dadosRisco = this.getRisco(this.estresse.numero, 4, this.$props.dados.estresse.descricao_area_risco)
    this.estresse.cor = dadosRisco.cor;
    this.estresse.progress = dadosRisco.progress;
    this.estresse.riscos = dadosRisco.desc;

    dadosRisco = this.getRisco(this.estiloVida.numero, 8, this.$props.dados.estilo_vida.descricao_area_risco)
    this.estiloVida.cor = dadosRisco.cor;
    this.estiloVida.progress = dadosRisco.progress;
    this.estiloVida.riscos = dadosRisco.desc;
  },
  methods: {
    getAreasDeRisco(areas_risco) {
      return areas_risco.length > 1
        ? "Você apresentou riscos nas seguintes áreas: " + areas_risco.join(", ")
        : "Você apresentou risco em " + areas_risco[0];
    },
    getRisco(nivel, limit = 10, areas_risco = null) {
      let descAreaRisco =
        areas_risco !== null
          ? this.getAreasDeRisco(areas_risco)
          : "Parabéns, você não apresentou riscos";
      let progressValues = {
        [this.LIMITE_4]: [0, 25, 50, 75, 100],
        [this.LIMITE_8]: [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100],
      };
      let progress = progressValues[limit]?.[nivel] ?? nivel + "0";
      let cor;
      if (nivel <= 1) {
        cor = this.COR_VERDE;
      } else if (nivel <= 3) {
        cor = this.COR_AZUL;
      } else {
        cor = this.COR_ROXA;
      }
      return { progress: progress, cor: cor, desc: descAreaRisco };
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom/relatorios.scss";
</style>

<template>
  <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
    <div class="card border-0 bg-blue">
      <div class="card-body font-weight-bolder font-medium-4 border-0 bg-blue text-center">
        Seus índices de saúde e bem-estar
      </div>
      <div class="card-body bg-light text-dark p-0">
        <div class="row no-gutters">
          <div class="col-12 col-md-4">
            <div class="card mb-0 border border-3 rounded-0" style="height: 250px">
              <div class="py-2 text-center font-weight-bolder font-medium-1">Seu peso vs. Faixa de peso saudável</div>
              <div class="card-body pb-1 px-1 pt-1">
                <div class="text-center font-weight-bolder text-white row justify-content-center">
                  <div class="font-medium-5 col-5">
                    <div class="py-2 rounded bg-blue" style="height: 7rem">
                      <strong class="font-medium-1 text-white">Seu peso</strong><br>{{ valuePeso }} Kg
                    </div>
                  </div>
                  <div class="font-medium-2 col-7">
                    <div class="py-1 rounded bg-green" style="height: 7rem">
                      <strong class="font-small-3 text-white">Faixa de peso saudável</strong>
                      <br>Entre {{ valuePesoIdealMin }} Kg<br>e {{ valuePesoIdealMax }} Kg
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="card mb-0 border border-3 rounded-0" style="height: 250px">
              <div class="py-1 text-center font-weight-bolder font-medium-1">Índice de Massa Corporal (IMC)</div>
              <div class="card-body pb-0 px-3 pt-2">
                <div class="row no-gutters">
                  <div v-for="(_val, key) in niveisIMC" :key="key" class="col-2 text-center">
                    <div v-if="getIMC(valueImc)===key" class="font-small-3 text-blue" style="font-weight: 900;">
                      <div class="font-small-4 text-blue">Seu resultado</div>
                      IMC {{ valueImc.toFixed(1) }}
                    </div>
                  </div>
                </div>
                <div class="row no-gutters font-small-3 font-weight-bolder text-monospace">
                  <div v-for="(val, key) in niveisIMC" :key="key" v-bind:class="[getIMC(valueImc)===key?'col-2 rounded border border-blue text-white border-2 text-center py-1 bg-blue bg-darken-2 px-0':'col-2 rounded bg-secondary bg-lighten-5 border border-2 border-white text-center py-1 px-0']">
                    <div class="font-small-4 font-weight-bold">{{ val.descricao }}</div>
                    <div class="font-small-3 font-weight-bold">{{ val.regra }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-12 col-md-4">
            <div class="card mb-0 border border-3 rounded-0">
              <div class="pt-2 pb-1 text-center font-medium-3 font-weight-bolder">Alimentação</div>
              <div class="card-body p-1 font-small-1">
                <div :class="[$props.print==true?'mb-2 text-center font-medium-4 font-weight-bolder':'mb-2 text-center font-medium-4 font-weight-bolder']" :style="{ color : alimentacaoIndiceColor }">{{ alimentacaoIndice }}</div>
                <VueApexCharts v-if="verifyEndChartsFill" :options="alimentacao.chartOptions" :series="alimentacao.series"></VueApexCharts>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card mb-0 border border-3 rounded-0">
              <div class="pt-2 pb-1 text-center font-medium-3 font-weight-bolder">Estresse</div>
              <div class="card-body p-1">
                <div :class="[$props.print==true?'mb-2 text-center font-medium-4 font-weight-bolder':'mb-2 text-center font-medium-4 font-weight-bolder']" :style="{ color : estresseIndiceColor }">{{ estresseIndice }}</div>
                <VueApexCharts v-if="verifyEndChartsFill" :options="estresse.chartOptions" :series="estresse.series"></VueApexCharts>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card mb-0 border border-3 rounded-0">
              <div class="pt-2 pb-1 text-center font-medium-3 font-weight-bolder">Atividade física</div>
              <div class="card-body p-1 font-small-1">
                <div :class="[$props.print==true?'mb-2 text-center font-medium-4 font-weight-bolder':'mb-2 text-center font-medium-4 font-weight-bolder']" :style="{ color : atividadeFisicaIndiceColor }">{{ atividadeFisicaIndice }}</div>
                <VueApexCharts v-if="verifyEndChartsFill" :options="atividadeFisica.chartOptions" :series="atividadeFisica.series"></VueApexCharts>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-0 border border-3 mb-0 border-top-0 py-1">
          <div class="row align-items-center font-medium-1 font-weight-bolder">
            <div class="col-12 col-md-3">
              <div class="font-weight-bolder text-right pl-1">Alimentação, Estresse e Atividade física:</div>
            </div>
            <div class="col-12 col-md-3">
              <div class="card mb-0 rounded-0">
                <div class="card-body p-1">
                  <div class="row no-gutters justify-content-center align-items-center">
                    <div class="col-auto">
                      <div class="bullet-legenda bg-green cursor-pointer float-right mr-1"></div>
                    </div>
                    <div class="col text-left text-green">Bom:<br>66% ou mais</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="card mb-0 rounded-0">
                <div class="card-body p-1">
                  <div class="row no-gutters justify-content-center align-items-center">
                    <div class="col-auto">
                      <div class="bullet-legenda bg-blue cursor-pointer float-right mr-1"></div>
                    </div>
                    <div class="col text-left text-blue">Atenção:<br>entre 33% e 66%</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="card mb-0 rounded-0">
                <div class="card-body p-1">
                  <div class="row no-gutters justify-content-center align-items-center">
                    <div class="col-auto">
                      <div class="bullet-legenda bg-purple cursor-pointer float-right mr-1"></div>
                    </div>
                    <div class="col text-left text-purple">Vamos melhorar?<br>33% ou menos</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BBadge,BProgress
} from 'bootstrap-vue'

import VueApexCharts from "vue-apexcharts";

const COLOR = {
  purple: '#aa228c',
  blue: '#2772c0',
  green: '#36ba00',
  light: '#ededed'
};

let COLOR_ALIMENTACAO = COLOR.purple;
let COLOR_ATIVIDADE = COLOR.purple;
let COLOR_ESTRESSE = COLOR.purple;

export default {
  components: {
    BBadge,BOverlay,VueApexCharts,BProgress
  },
  props: {
    dados: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    print: {
      type: Boolean,
      default() {
        return false;
      },
      required: false,
    },
  },
  data() {
    return {
      legenda: {},
      loadingShow: true,
      niveisIMC: [
        {descricao: 'Baixo peso',  regra: 'Até 18.5'},
        {descricao: 'Peso normal',  regra: 'De 18.6 à 24.9'},
        {descricao: 'Sobrepeso',   regra: 'De 25 à 29.9'},
        {descricao: 'Obesidade 1', regra: 'De 30 à 34.9'},
        {descricao: 'Obesidade 2', regra: 'De 35 à 39.9'},
        {descricao: 'Obesidade 3', regra: 'Acima de 40'},
      ],

      pesoVsIdeal: {},

      alimentacao: {},
      alimentacaoIndice: '',
      alimentacaoIndiceColor: '',

      atividadeFisica: {},
      atividadeFisicaIndice: '',
      atividadeFisicaIndiceColor: '',

      estresse: {},
      estresseIndice: '',
      estresseIndiceColor: '',

      valuePeso: 0,
      valuePesoIdealMin: 0,
      valuePesoIdealMax: 0,
      valueImc: 0,
      valueAlimentacao: 0,
      valueEstresse: 0,
      valueAtividade: 0,

      mediaPontuacaoDoIndiceDeProdutividadeIndividual: {},
      verifyEndChartsFill: false
    }
  },
  mounted() {

    this.valuePeso= this.$props.dados.peso[0].peso;
    this.valuePesoIdealMin= this.$props.dados.peso[0].peso_ideal_min;
    this.valuePesoIdealMax= this.$props.dados.peso[0].peso_ideal_max;
    this.valueImc= this.$props.dados.peso[0].imc_valor;
    this.valueAlimentacao= this.$props.dados.alimentacao.perc_indice;
    this.valueEstresse= this.$props.dados.estresse.perc_indice;
    this.valueAtividade= this.$props.dados.atividadeFisica.perc_indice;

    this.generateAlimentacao();
    this.generateAtividadeFisica();
    this.generateEstresse();
    this.verifyEndChartsFill = true;
  },
  methods: {

    generateAlimentacao() {
      if(this.valueAlimentacao >= 66) {
        this.alimentacaoIndice = 'Seu índice está bom';
        COLOR_ALIMENTACAO = COLOR.green;
        this.alimentacaoIndiceColor = COLOR.green;
      } else if(this.valueAlimentacao >= 33 && this.valueAlimentacao < 66) {
        this.alimentacaoIndice = 'Seu índice requer atenção';
        COLOR_ALIMENTACAO = COLOR.blue;
        this.alimentacaoIndiceColor = COLOR.blue;
      } else {
        this.alimentacaoIndice = 'Seu índice precisa melhorar';
        COLOR_ALIMENTACAO = COLOR.purple;
        this.alimentacaoIndiceColor = COLOR.purple;
      }

      this.alimentacao = {
        series: [this.valueAlimentacao],
        chartOptions: {
          chart: {
            type: 'radialBar',
            offsetY: -10,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: COLOR.light,
                margin: 10,
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -5,
                  fontSize: '40px',
                  fontFamily: 'Arial, sans-serif',
                  fontWeight: 'bold',
                  color: COLOR_ALIMENTACAO
                }
              }
            }
          },
          fill: {
            colors:  [COLOR_ALIMENTACAO],
          },
          grid: {
            padding: {
              top: -10
            },
          },
          labels: ['Alimentação'],
        }
      }
    },

    generateAtividadeFisica() {
      if(this.valueAtividade >= 66) {
        this.atividadeFisicaIndice = 'Seu índice está bom';
        COLOR_ATIVIDADE = COLOR.green;
        this.atividadeFisicaIndiceColor = COLOR.green;
      } else if(this.valueAlimentacao >= 33 && this.valueAlimentacao < 66) {
        this.atividadeFisicaIndice = 'Seu índice requer atenção';
        COLOR_ATIVIDADE = COLOR.blue;
        this.atividadeFisicaIndiceColor = COLOR.blue;
      } else {
        this.atividadeFisicaIndice = 'Seu índice precisa melhorar';
        COLOR_ATIVIDADE = COLOR.purple;
        this.atividadeFisicaIndiceColor = COLOR.purple;
      }

      this.atividadeFisica = {
        series: [this.valueAtividade],
        chartOptions: {
          chart: {
            type: 'radialBar',
            offsetY: -10,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: COLOR.light,
                margin: 10,
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -10,
                  fontSize: '40px',
                  fontFamily: 'Arial, sans-serif',
                  fontWeight: 'bold',
                  color: COLOR_ATIVIDADE
                }
              }
            }
          },
          fill: {
            colors:  [COLOR_ATIVIDADE],
          },
          grid: {
            padding: {
              top: -10
            },
          },
          labels: ['Atividade física'],
        }
      }
    },

    generateEstresse() {
      if(this.valueEstresse >= 66) {
        this.estresseIndice = 'Seu índice está bom';
        COLOR_ESTRESSE = COLOR.green;
        this.estresseIndiceColor = COLOR.green;
      } else if(this.valueEstresse >= 33 && this.valueEstresse < 66) {
        this.estresseIndice = 'Seu índice requer atenção';
        COLOR_ESTRESSE = COLOR.blue;
        this.estresseIndiceColor = COLOR.blue;
      } else {
        this.estresseIndice = 'Seu índice precisa melhorar';
        COLOR_ESTRESSE = COLOR.purple;
        this.estresseIndiceColor = COLOR.purple;
      }

      this.estresse = {
        series: [this.valueEstresse],
        chartOptions: {
          chart: {
            type: 'radialBar',
            offsetY: -10,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: COLOR.light,
                margin: 10,
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -10,
                  fontSize: '40px',
                  fontFamily: 'Arial, sans-serif',
                  fontWeight: 'bold',
                  color: COLOR_ESTRESSE
                }
              }
            }
          },
          fill: {
            colors:  [COLOR_ESTRESSE],
          },
          grid: {
            padding: {
              top: -10
            },
          },
          labels: ['Estresse'],
        }
      }
    },

    getIMC(val){
      val = val.toFixed(1)
      if(val <= 18.5) {
        return 0
      } else if(val <= 24.9) {
        return 1
      } else if(val <= 29.9) {
        return 2
      } else if(val <= 34.9) {
        return 3
      } else if(val <= 39.9) {
        return 4
      } else {
        return 5
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom/relatorios.scss";
</style>

<template>
    <div class="card rounded-0 mb-0 border-0">
        <div class="card-body p-1 font-medium-3 font-weight-bolder text-center">
            <div class="row justify-content-center mb-2 align-items-center">
                <div class="col-5">
                    <div class="bullet-legenda bg-green cursor-pointer float-right"></div>
                </div>
                <div class="col text-left">{{ $props.empresa?'Acima da média':'Bom' }}</div>
            </div>
            <div class="row justify-content-center mb-2 align-items-center">
                <div class="col-5">
                    <div class="bullet-legenda bg-blue cursor-pointer float-right"></div>
                </div>
                <div class="col text-left">{{ $props.empresa?'Na média':'Atenção' }}</div>
            </div>
            <div class="row justify-content-center align-items-center">
                <div class="col-5">
                    <div class="bullet-legenda bg-purple cursor-pointer float-right"></div>
                </div>
                <div class="col text-left">{{ $props.empresa?'Abaixo da média':'Precisa melhorar' }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BOverlay, BBadge
} from 'bootstrap-vue'

import VueApexCharts from "vue-apexcharts";

const COLOR = {
    purple: '#9342cf',
    purpleDark: '#5f05cc',
    blue: '#0e67c4',
    blueDark: '#15249a',
    green: '#2ED24AFF',
    light: '#ededed'
};

export default {
    components: {
        BBadge,BOverlay,VueApexCharts
    },
    props: {
        empresa: {
            type: Boolean,
            default() {
                return false;
            },
            required: false,
        },
    },
    data() {
        return {
            legenda: {},
        }
    },
    mounted() {
        this.setLegenda()
    },
    methods: {
        setLegenda(){
            this.legenda = {
                excelente: {
                    color: COLOR.green,
                    text: 'Excelente'
                },
                bom: {
                    color: COLOR.blue,
                    text: 'Bom'
                },
                regular: {
                    color: COLOR.blueDark,
                    text: 'Regular'
                },
                atencao: {
                    color: COLOR.purpleDark,
                    text: 'Atenção'
                },
                vamosMelhorar: {
                    color: COLOR.purple,
                    text: 'Vamos melhorar?'
                },
            };
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom/relatorios.scss";
</style>

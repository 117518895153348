<template>
  <b-overlay :show="loading" rounded="lg" opacity="0.3">
    <div v-if="reportEmpty" class="text-center mt-5">
      <h2>Não foi possível gerar o relatório</h2>
      <h4 class="mb-2">O(A) colaborador(a) selecionado não possui dados suficientes para gerar o Relatório Individual</h4>
    </div>
    <div v-else>
      <div v-if="loading || reportEmpty">
        <div>
          <div class="text-center py-5 mt-5">
            <h2 class="mt-5 pt-5">Aguarde, buscando dados e preparando o <strong>Relatório Individual</strong></h2>
            <h4 class="mb-0">O relatório é feito com base nas respostas do colaborador, caso ele não o tenha feito, não será possível realizá-lo.</h4>
          </div>
        </div>
      </div>
      <div v-else class="card card-body">
        <div class="text-center">
          <h3 class="mb-1">Relatório do colaborador {{ nome }}</h3>
          <div class="mb-2 font-medium-1">O relatório individual está pronto.</div>
          <div class="row mb-1">
            <div class="col">
              <b-button
                variant="primary"
                class="btn btn-lg"
                @click.prevent="downloadRelatorioIndividualPDF"
                :disabled="loadingDownloadPdf"
              >
                                <span v-if="!loadingDownloadPdf">
                                    <feather-icon
                                      icon="DownloadIcon"
                                      size="22"
                                      class="mr-1"
                                    />
                                </span>
                <span v-if="loadingDownloadPdf" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
                <span>Download do arquivo</span>
              </b-button>
            </div>
          </div>
        </div>
        <div>
          <div class="bg-light text-monospace p-5">
            <div class="card rounded-0 border border-lg border-blue border-3">
              <div class="card-body">
                <div class="row align-items-center justify-content-around">
                  <div class="col-12 col-md-3">
                    <div class="font-weight-bolder font-large-2 text-blue">
                      <img title="Planeja Saúde" alt="Planeja Saúde" src="@/assets/images/logo/PlanejaSaudeLogo.png" width="200">
                    </div>
                  </div>
                  <div class="col-12 col-md-6 text-center text-md-right">
                    <div class="font-weight-bolder font-large-1 text-blue">Avaliação Pessoal de Saúde</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card rounded-0 border border-lg border-blue border-3">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-12 col-md">
                    <div class="font-weight-bold font-medium-3 text-blue">Resultado de <strong>{{ nome }}</strong></div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="font-weight-bold font-medium-3 text-blue text-left text-md-right text-capitalize">{{ this.formatDate(new Date()) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <CardSeuEstiloDeVida :dados="cards.seuEstiloDeVida"/>
            <CardSeuPeso :dados="cards.seuPeso" :print="false"/>
            <CardFatoresDeRisco :dados="cards.fatoresDeRisco"/>
            <CardProgramacao :dados="cards.programacao"/>
            <div class="card rounded-0 border border-lg border-blue border-3">
              <div class="card-body">
                <div class="row align-items-center justify-content-around">
                  <div class="col-12 col-md-5">
                    <div class="font-weight-bolder font-large-2 text-blue">
                      <img title="Planeja Saúde" alt="Planeja Saúde" src="@/assets/images/logo/PlanejaSaudeLogo.png" width="200">
                    </div>
                  </div>
                  <div class="col-12 col-md-5">
                    <img title="SESI" alt="SESI" src="@/assets/images/logo/logo-sesi.png" width="200" class="float-right">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>

import {BBadge, BSpinner, BOverlay, BButton} from 'bootstrap-vue'
import CardSeuEstiloDeVida from "@/views/components/custom/relatorios/planeja-saude/individual/CardSeuEstiloDeVida";
import CardSeuPeso from "@/views/components/custom/relatorios/planeja-saude/individual/CardSeuPeso";
import CardFatoresDeRisco from "@/views/components/custom/relatorios/planeja-saude/individual/CardFatoresDeRisco";
import CardProgramacao from "@/views/components/custom/relatorios/planeja-saude/individual/CardProgramacao";

export default {
  components: {
    CardProgramacao,
    CardSeuPeso,
    CardSeuEstiloDeVida,
    CardFatoresDeRisco,
    BBadge,
    BSpinner,
    BOverlay,
    BButton
  },

  data() {
    return {
      reportName: 'relatorio_individual',
      reportEmpty: false,
      loading: true,
      idFase: null,
      idColaborador: null,
      nome: 'Aguarde enquanto buscamos seus dados',
      cards: {
        seuEstiloDeVida: null,
        seuPeso: null,
        fatoresDeRisco: null,
        programacao: null
      },
      loadingDownloadPdf: false
    }
  },

  beforeCreate() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', {type: 'hidden'});
  },

  created() {
    this.idColaborador = this.$route.query.id_colaborador;
    this.idFase = this.$route.query.id_fase;
    this.getDadosRelatorio()
  },

  methods: {
    async downloadRelatorioIndividualPDF() {
      this.loadingDownloadPdf = true;
      await this.$http.get(this.$api.PlanejaSaude.downloadRelatorioIndividualPDF(this.idFase, this.idColaborador), {
        responseType: "blob"
      })
        .then(response => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(response.data);
          link.download = response.headers["content-name-file"];

          link.click();

          URL.revokeObjectURL(link.href);
        });
      this.loadingDownloadPdf = false;
    },
    formatDate(date) {
      let mes = [
        'janeiro', 'fevereiro', 'março', 'abril',
        'maio', 'junho', 'julho', 'agosto', 'setembro',
        'outubro', 'novembro', 'dezembro'
      ][date.getMonth()]
      let ano = date.getFullYear()

      return `${mes} / ${ano}`
    },
    async getDadosRelatorio() {
      await this.$http.get(this.$api.PlanejaSaude.retornaDadosRelatorioIndividual(this.idFase, this.idColaborador))
        .then((response) => {
          if(response.data === null || response.data===''){
            this.reportEmpty = true;
          }
          this.cards.seuEstiloDeVida = {estiloDeVidaIndividual: JSON.parse(response.data.estilo_vida), indice: JSON.parse(response.data.indices)[1]};
          this.cards.seuPeso = {peso: JSON.parse(response.data.peso_ideal), alimentacao: JSON.parse(response.data.indices)[0], atividadeFisica: JSON.parse(response.data.indices)[2], estresse: JSON.parse(response.data.indices)[3]}
          this.cards.fatoresDeRisco = {
            estilo_vida: JSON.parse(response.data.fatores_risco)[0],
            estresse: JSON.parse(response.data.fatores_risco)[1],
            enfermidade: JSON.parse(response.data.fatores_risco)[2]
          }
          this.cards.programacao = {
            colaborador: {
              nome: response.data.nome_colaborador,
              nascimento: response.data.nascimento
            }
          };
          this.nome = response.data.nome_colaborador;
          let nome = this.nome.replace(/\W+/g, '_').toLowerCase();
          this.reportName = this.reportName+'_'+nome+'.pdf';
        }).catch(() => {
          this.reportEmpty = true;
        });
      this.loading = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom/relatorios.scss";

.text-report > * {
  font-family: Tahoma, Helvetica, Arial, sans-serif !important;
}
</style>

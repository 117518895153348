<template>
  <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
    <div class="card border-0 bg-blue">
      <div class="card-body font-weight-bolder font-medium-4 border-0 bg-blue text-center rounded-top">
        Seu estilo de vida
      </div>
      <div class="card-body bg-white text-dark p-0">
        <div class="card border-0 rounded-0 mb-0">
          <div class="card-body px-4">
            <div class="row no-gutters justify-content-center align-items-center">
              <div :class="[$props.print?'col-5':'col-6']">
                <VueApexCharts v-if="verifyEndChartsFill" type="radialBar" :options="seuEstiloDeVida.chartOptions" :series="seuEstiloDeVida.series"></VueApexCharts>
              </div>
              <div :class="[$props.print?'col-7':'col-6']">
                <h2 class="font-weight-bolder mb-0 text-right">Seu índice de estilo de vida é de {{ valueSeuEstiloDeVida.toFixed(1).replace('.',',') }}</h2>
                <h4 class="font-weight-bolder mb-1 text-right">O índice ideal deve estar acima de 8,0</h4>
                <div class="font-small-2 text-justify bg-light p-1 rounded">
                  Este índice avalia o estilo de vida do trabalhador a partir de indicadores psicossociais e comportamentais de risco à saúde. Ele varia de 0 a 10 pontos e é determinado por fatores como tabagismo, abuso de bebidas alcoólicas, baixa frequência de consumo de frutas e verduras, entre outros. Um IGEV mais elevado significa que o trabalhador está menos exposto a riscos à saúde.
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0 bg-white border-top border-3">
            <div class="py-1 text-center font-weight-bolder font-medium-1">Sua pontuação em cada área do estilo de vida</div>
            <div class="row no-gutters justify-content-center align-items-center">
              <div class="col-7">
                <div class="font-small-1">
                  <VueApexCharts v-if="verifyEndChartsFill" :options="pontuacaoDoIndiceDeVidaIndividual.chartOptions" :series="pontuacaoDoIndiceDeVidaIndividual.series"></VueApexCharts>
                </div>
              </div>
              <div class="col-5">
                <CardLegenda />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BBadge
} from 'bootstrap-vue'

import VueApexCharts from "vue-apexcharts";
import CardLegenda from "@/views/components/custom/relatorios/planeja-saude/individual/CardLegenda";

const COLOR = {
  purple: '#aa228c',
  blue: '#2772c0',
  green: '#36ba00',
  light: '#ededed'
};

let COLOR_ESTILO_VIDA = COLOR.purple;

export default {
  components: {
    CardLegenda,
    BBadge,BOverlay,VueApexCharts
  },
  props: {
    dados: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    print: {
      type: Boolean,
      default() {
        return false;
      },
      required: false
    }
  },
  data() {
    return {
      categoriaIndices: {
        '2':{desc: 'Abuso de Álcool', value: 0, position: 1},
        '1':{desc: 'Tabagismo', value: 0, position: 0},
        '5':{desc: 'Consumo de Refrigerantes', value: 0, position: 7},
        '7':{desc: 'Nível de Estresse', value: 0, position: 6},
        '3':{desc: 'Consumo de Frutas e Verduras', value: 0, position: 3},
        '6':{desc: 'Atividade Física', value: 0, position: 4},
        '8':{desc: 'Qualidade de Relacionamentos', value: 0, position: 2},
        '9':{desc: 'Qualidade do Sono', value: 0, position: 9},
        '20':{desc: 'Tempo de Tela', value: 0, position: 5},
        '17':{desc: 'Autopercepção de Saúde', value: 0, position: 8},
      },
      loadingShow: true,

      descricaoCategorias: [],

      seuEstiloDeVida: {},
      valueSeuEstiloDeVida: 0,

      pontuacaoDoIndiceDeVidaIndividual: {},
      valuePontuacaoDoIndiceDeVidaIndividual: [],
      descricaoIndicesVidaIndividual: [],

      verifyEndChartsFill: false

    }
  },
  mounted() {
    this.setValueSeuEstiloDeVida(this.$props.dados.indice)
    this.setValuePontuacaoDoIndiceDeVidaIndividual(this.$props.dados.estiloDeVidaIndividual)
    this.verifyEndChartsFill = true;
  },
  methods: {
    setValueSeuEstiloDeVida(_data){
      this.valueSeuEstiloDeVida = _data.valor_indice;

      if(this.valueSeuEstiloDeVida >= 8) {
        COLOR_ESTILO_VIDA = COLOR.green
      } else if(this.valueSeuEstiloDeVida >= 5 && this.valueSeuEstiloDeVida < 8) {
        COLOR_ESTILO_VIDA = COLOR.blue
      } else {
        COLOR_ESTILO_VIDA = COLOR.purple
      }

      this.generateChartSeuEstiloDeVida();
    },
    setValuePontuacaoDoIndiceDeVidaIndividual(_data){
      let _value = this.valuePontuacaoDoIndiceDeVidaIndividual;
      let _desc = this.descricaoIndicesVidaIndividual;
      let _categoriaIndices = this.categoriaIndices;
      _data.forEach(function (item) {
        _categoriaIndices[item.id_area].value = item.pontuacao_area;
      })

      for (const [key,value] of Object.entries(_categoriaIndices)) {
        _value[value.position] = value.value;
        _desc[value.position] = value.desc;
      }

      this.valuePontuacaoDoIndiceDeVidaIndividual = _value;
      this.descricaoIndicesVidaIndividual = _desc;
      this.generateChartPontuacaoDoIndiceDeVidaIndividual()
    },

    generateChartSeuEstiloDeVida() {
      this.seuEstiloDeVida = {
        series: [(this.valueSeuEstiloDeVida*10)],
        chartOptions: {
          chart: {
            type: 'radialBar',
            offsetY: -10,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: COLOR.light,
                margin: 10,
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -10,
                  fontSize: '45px',
                  fontFamily: 'Arial, sans-serif',
                  fontWeight: 'bold',
                  color: COLOR_ESTILO_VIDA,
                  formatter: function (val) {
                    return (val/10).toFixed(1).replace('.',',')
                  },
                }
              }
            }
          },
          fill: {
            colors:  [COLOR_ESTILO_VIDA],
          },
          grid: {
            padding: {
              top: -10
            }
          },
          labels: ['Seu índice de estilo de vida'],
        }
      }
    },

    generateChartPontuacaoDoIndiceDeVidaIndividual(){
      this.pontuacaoDoIndiceDeVidaIndividual = {
        series: [
          {name:'Pontuação',data:this.valuePontuacaoDoIndiceDeVidaIndividual}
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          colors:  [function({value}) {
            if(value < 5){
              return COLOR.purple
            }
            if(value >= 5 && value < 7 ){
              return COLOR.blue
            }
            return COLOR.green
          }],
          legend: {
            display: false,
          },
          plotOptions: {
            bar: {
              strokeWidth: 0,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: true,
          },
          xaxis: {
            categories: this.descricaoIndicesVidaIndividual,
            min: 0,
            max: 10,
          },
          yaxis: {
            labels: {
              maxWidth: 250,
            }
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              },
            },
            yaxis: {
              lines: {
                show: false
              },
            },
          },
          fill: {
            opacity: 1,
          },
        },
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom/relatorios.scss";
</style>
